import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CartItems from "./CartItems";
import CartIcon from "../icons/CartIcon";
import OrderSummary from "./OrderSummary";
import QuickAdd from "./QuickAdd";
import MessageWarning from "../MessageWarning";
import FreightHandlingCodes from "./FreightHandlingCodes";
import HeaderRequestDate from "./HeaderRequestDate";
import MessageStandard from "../MessageStandard";
import PaymentTerms from "./PaymentTerms";
import Loading from "../Loading";
import Authorize from "../Authorize";
import * as Roles from "../../resources/UserRole";
import UserHelper from "../../helpers/UserHelper";
import CustomerService from "../../services/Customer";
import { withCartConfig } from "../../hooks/CartConfigContext";
import {withStorefrontConfig } from "../../hooks/StorefrontSettingsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "../../modules/react-csv";
import ImportCart from "./ImportCart";
import IncoTerms from "./IncoTerms";
import ClearCartButton from "./../buttons/ClearCartButton";
import AngleDown from "../icons/AngleDown";
import NotificationBanner from "./../banner/NotificationBanner";

export const ItemsSummary = (props) => {
    const [importItemsFetching, setImportItemsFetching] = useState(false);

    useEffect(() => {
        loadDefaultPaymentTerms();
    }, []);
    useEffect(() => {
        renderContent();
    }, [props.items]);
    
    useEffect(() => {
        if (props.selectedCustomer !== undefined) {          
            updateSelectedBranch();
        }
    }, [props.selectedCustomer]);

    const updateSelectedBranch = () => {
        if (props.selectedBranch) {
            props.selectedBranchUpdate({ target: { value: "" } });
        }
    };

    const checkCreditHold = () => {
        if (props.selectedCustomer && props.currentUser.storefront === "6400") {
            return CustomerService.checkCreditHold(props.selectedCustomer);
        } else {
            return false;
        }
    };

    const loadDefaultPaymentTerms = () => {
        props.loadCustomerDefaultPaymentTerms();
    };

    const getCSVData = () => {
        return [];
    };

    const getCSVHeaders = () => {
        const headers = [
            { label: props.localized.ItemNumber, key: "sku" },
            { label: props.localized.QuantityLabel, key: "qty" },
        ];

        return headers;
    };

    const renderContent = () => {
        const { items, fetching, editingQuote } = props;

        let hasItems = items && items.length > 0;

        let checkPriceAvailability = UserHelper.checkPriceAvailability(
            props.selectedCustomer,
            props.currentUser.storefrontNumber
        );

        let creditHold = checkCreditHold();

        const customerWarningMessage =
            props.selectedCustomer.isActive === false
                ? props.localized.InActiveCustomerSelectedWarningMessage
                : props.localized.ShipToNotSelectedWarningMessage;

        return (
            <div style={{ padding: "2.5rem" }}>
                {creditHold && (
                    <div id="CreditHoldWarning">
                        <MessageWarning
                            title={""}
                            message={props.localized.CreditHold_Warning}
                        />
                    </div>
                )}

                {props.selectedCustomer && !checkPriceAvailability && (
                    <div id="CustomerIsBillToWarning">
                        <MessageWarning
                            title={props.localized.ShipToNotSelectedWarningTitle}
                            message={customerWarningMessage}
                        />
                    </div>
                )}

                <NotificationBanner
                    classOverride="mb-4"
                    page="QuickOrder"
                    locale={props.StorefrontConfig.locale}
                />

                {props.selectedCustomer &&
                    checkPriceAvailability &&
                    !creditHold &&
                    props.CartConfig.cartSettingsLoaded && (
                        <>
                            <div className="fele-cart-header">
                                <div className="d-lg-flex justify-content-between sticky-top">
                                    <div className="d-flex justify-content-start">
                                        <div className="title">
                                            <CartIcon />

                                            <div className="heading1">
                                                {props.localized.EditTitle}
                                            </div>
                                        </div>
                                    </div>

                                    {props.CartConfig.settings.ShowImportCart === true && (
                                        <Authorize
                                            hasRoles={[Roles.BULK_IMPORT]}
                                            displayMessage={false}
                                        >
                                            <div
                                                className="align-self-lg-end align-self-md-start d-md-inline d-xl-flex"
                                                style={{
                                                    backgroundColor:
                                                        props.CartConfig.childComponents.NewFeature
                                                            .shown === true
                                                            ? "rgba(254, 255, 183, 1)"
                                                            : "none",
                                                }}
                                            >
                                                {props.CartConfig.childComponents.NewFeature.shown ===
                                                    true && (
                                                        <div
                                                            className="label text-nowrap px-3"
                                                            style={{
                                                                color: "#FF8300",
                                                                padding: "10px",
                                                            }}
                                                        >
                                                            {props.localized.New}
                                                        </div>
                                                    )}
                                                <div className="export-button button-wrapper px-3">
                                                    <CSVLink
                                                        id="import-csv-link"
                                                        separator={props.localized.ExcelSeparator}
                                                        data={getCSVData()}
                                                        filename={"Download Template"}
                                                        headers={getCSVHeaders()}
                                                        className="button button-alt text-nowrap px-5"
                                                        style={{
                                                            paddingTop: "5px",
                                                            textDecoration: "none",
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faFileExcel} />{" "}
                                                        {props.localized.DownloadExcelTemplate}
                                                    </CSVLink>
                                                </div>

                                                <div className="button-wrapper text-nowrap px-1">
                                                    <ImportCart
                                                        localized={props.localized}
                                                        currentUser={props.currentUser}
                                                        cartConfig={props.CartConfig}
                                                        selectedCustomer={props.selectedCustomer}
                                                        setImportItemsFetching={setImportItemsFetching}
                                                        cartItems={items}
                                                    />
                                                </div>
                                            </div>
                                        </Authorize>
                                    )}
                                </div>

                                <div className="row">
                                    <div className="col-12 justify-content-end d-flex gy-2">
                                        <p className="text-right">
                                            {" "}
                                            {props.localized.DisclaimerText}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {props.selectedCustomer &&
                                checkPriceAvailability &&
                                !creditHold &&
                                props.CartConfig.cartSettingsLoaded && (
                                    <>
                                        <div className="fele-my-order-settings">
                                            <FreightHandlingCodes
                                                localized={props.localized}
                                                isFetching={props.fetching}
                                                handleChange={props.freightHandlingCodeUpdate}
                                                freightHandlingCode={props.freightHandlingCode}
                                                currentUser={props.currentUser}
                                            />

                                            <PaymentTerms
                                                isFetching={props.fetching}
                                                editingQuote={editingQuote}
                                                selectedCustomer={props.selectedCustomer}
                                                currentUser={props.currentUser}
                                                updatePaymentTerms={props.paymentTermsUpdated}
                                                paymentTermId={props.paymentTermId}
                                                paymentInstrument={props.paymentInstrument}
                                                storefrontPaymentTerms={
                                                    props.CartConfig.storefrontPaymentTerms
                                                }
                                                isCashPayment={props.isCashPayment}
                                            />
                                            {
                                                (props.StorefrontConfig.settingsList.UseCartBranchSelection != '0' && !editingQuote) &&
                                                <div className="fele-cart-freight-handling-codes">
                                                    <div className="label">
                                                        {props.localized.BranchSelection}
                                                    </div>
                                                    <div className="fele-content">
                                                        <div className="input-wrapper">
                                                            <div className="select-wrapper">
                                                            <select disabled={props.isFetching || !UserHelper.hasRoles([Roles.CAN_SELECT_FREIGHT_CODES], props.currentUser)} onChange={props.selectedBranchUpdate} value={props.selectedBranch}>
                                                                <option value={0}>{props.localized.SelectBranch}</option>
                                                                    {
                                                                        props.branches && props.branches.map(
                                                                            (branch, index) =>
                                                                                <option key={index} value={branch.branchNumber}>{branch.branchName}</option>
                                                                        )}
                                                                </select>
                                                             <AngleDown />
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <HeaderRequestDate
                                                updateDate={props.updateDate}
                                                requestDate={props.requestDate}
                                                dateFormat={props.dateFormat}
                                            />

                                            {!props.repriceMessageDisplayed && (
                                                <IncoTerms
                                                    localized={props.localized}
                                                    termsAlignStyling={true}
                                                    subTotal={props.subTotal}
                                                    seprateDiv={false}
                                                />
                                            )}
                                        </div>

                                        <div
                                            className="d-lg-flex justify-content-between sticky-top"
                                            style={{
                                                top: "10.7rem",
                                                backgroundColor: "white",
                                                borderBottom: "0.1rem solid #E8E8E8",
                                                paddingBottom: "12px",
                                            }}
                                        >
                                            <div>
                                                <QuickAdd
                                                    className="d-inline-block flex-nowrap"
                                                    currentUser={props.currentUser}
                                                    addItemToCart={props.addItemToCart}
                                                    localized={props.localized}
                                                    selectedCustomer={props.selectedCustomer}
                                                    cartLines={props.items.length}
                                                />
                                            </div>

                      <div className="align-self-end d-flex gap-4 mt-3">
                        {props.repriceMessageDisplayed && (
                          <div className="reprice-button d-flex gap-5">
                            {!editingQuote && (
                              <ClearCartButton
                                count={items.length}
                                fetching={fetching}
                                clearCart={props.clearCart}
                                text={props.localized.ButtonText_ClearCart}
                                text2={props.localized.ButtonText_ClearCart_Line}
                              />
                            )}

                                                        <div className="button-wrapper">
                                                            <button
                                                                data-cy="reprice-order"
                                                                className="button reprice-cart-button text-nowrap px-5"
                                                                disabled={props.items.length === 0}
                                                                onClick={props.repriceCart}
                                                            >
                                                                {props.localized.ButtonText_Reprice}
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}

                        {!props.repriceMessageDisplayed && (
                          <div className="d-flex flex-column gap-1 flex-xxl-row gap-xxl-5 align-self-xxl-end">
                            {!editingQuote && (
                              <ClearCartButton
                                count={items.length}
                                fetching={fetching}
                                clearCart={props.clearCart}
                                text={props.localized.ButtonText_ClearCart}
                                text2={props.localized.ButtonText_ClearCart_Line}
                              />
                            )}

                                                        <div className="d-block d-lg-flex quote-button">
                                                            {!editingQuote && (
                                                                <Authorize
                                                                    hasRoles={[Roles.PLACE_QUOTES]}
                                                                    displayMessage={false}
                                                                >
                                                                    <div className="button-wrapper">
                                                                        <button
                                                                            data-cy="review-order"
                                                                            className="button place-quote-button text-nowrap px-5"
                                                                            onClick={props.reviewQuote}
                                                                        >
                                                                            {props.localized.ButtonText_ReviewQuote}
                                                                        </button>
                                                                    </div>
                                                                </Authorize>
                                                            )}
                                                        </div>

                                                        <div className="button-wrapper">
                                                            {!editingQuote &&
                                                                !props.repriceMessageDisplayed && (
                                                                    <Authorize
                                                                        hasRoles={[Roles.PLACE_ORDERS]}
                                                                        displayMessage={false}
                                                                    >
                                                                        <button
                                                                            data-cy="review-order"
                                                                            className="button review-button text-nowrap px-5"
                                                                            onClick={props.reviewIfItemsAvailable}
                                                                        >
                                                                            {props.localized.ButtonText_ReviewOrder}
                                                                        </button>
                                                                    </Authorize>
                                                                )}

                                                            {editingQuote &&
                                                                !props.repriceMessageDisplayed && (
                                                                    <button
                                                                        data-cy="review-order"
                                                                        className="button review-button text-nowrap px-5"
                                                                        onClick={props.reviewIfItemsAvailable}
                                                                    >
                                                                        {props.localized.ButtonText_ReviewQuote}
                                                                    </button>
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}
                        </>
                    )}

                {importItemsFetching === true && <Loading type="brand" />}

                {(items && items.length === 0) || creditHold ? (
                    <div className="fele-empty-cart">
                        <MessageStandard
                            icon={<CartIcon hideCount={true} />}
                            line1={props.localized.CartEmptyMessage}
                        />
                    </div>
                ) : (
                    props.selectedCustomer &&
                    checkPriceAvailability &&
                    hasItems &&
                    !creditHold && (
                        <>
                            {!fetching && !importItemsFetching && (
                                <CartItems
                                    key={items.length}
                                    selectedCustomer={props.selectedCustomer}
                                    dateFormat={props.dateFormat}
                                    currentUser={props.currentUser}
                                    items={props.items}
                                    branchVerificationItems={props.branchVerificationItems}
                                    updateLineItemQuantity={props.updateLineItemQuantity}
                                    removeItem={props.removeItem}
                                    updateCartItem={props.updateCartItem}
                                    components={props.components}
                                    pcDiscountCodes={props.CartConfig.pcDiscountCodes}
                                    headerRequestDate={props.requestDate}
                                    repriceCart={props.repriceCart}
                                    calculating={props.calculating}
                                    updateRequestDate={props.updateRequestDate}
                                />
                            )}

                            {fetching && <Loading type="brand" />}
                            <OrderSummary
                                localized={props.localized}
                                subTotal={props.subTotal}
                                currency={props.currency}
                                fetching={fetching}
                                callback={props.nextStep}
                                selectedCustomer={props.selectedCustomer}
                            />
                        </>
                    )
                )}
            </div>
        );
    };

    return (
        <>
            {props.CartConfig.cartConfigLoaded !== true &&
                props.CartConfig.cartSettingsLoaded !== true && (
                    <Loading type="brand" />
                )}

            {props.CartConfig.cartConfigLoaded === true &&
                props.CartConfig.cartSettingsLoaded === true &&
                renderContent()}
        </>
    );
};

ItemsSummary.propTypes = {
    selectedCustomer: PropTypes.object,
    currentUser: PropTypes.object,
    items: PropTypes.array,
    branchVerificationITems: PropTypes.array,
    freightHandlingCode: PropTypes.string,
    itemCount: PropTypes.number,
    paymentInstrument: PropTypes.string,
    paymentTermId: PropTypes.string,
    dateFormat: PropTypes.string,
    repriceMessageDisplayed: PropTypes.bool,
    fetching: PropTypes.bool,
    editingQuote: PropTypes.bool,
    subTotal: PropTypes.number,
    currency: PropTypes.string,
    headerRequestDate: PropTypes.string,
    updateLineItemQuantity: PropTypes.func,
    removeItem: PropTypes.func,
    clearCart: PropTypes.func,
    updateCartItem: PropTypes.func,
    addItemToCart: PropTypes.func,
    updateOrderHeader: PropTypes.func,
    paymentTermsUpdated: PropTypes.func,
    freightHandlingCodeUpdate: PropTypes.func,
    updateDate: PropTypes.func,
    reviewQuote: PropTypes.func,
    reviewIfItemsAvailable: PropTypes.func,
    repriceCart: PropTypes.func,
    localized: PropTypes.object,
    loadCustomerDefaultPaymentTerms: PropTypes.func,
    calculating: PropTypes.bool,
    updateRequestDate: PropTypes.bool,
};

export default withCartConfig(withStorefrontConfig(ItemsSummary));