import React from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Price from './Price';
import Availability from './Availability';
import Loading from '../Loading'
import Notifications from '../../services/Notifications';
import Authorize from '../Authorize';
import MessageWarning from '../MessageWarning';
import Api from '../../services/Api';
import * as cartActions from '../../actions/cartActions';
import SummaryAvailability from './SummaryAvailability';
import * as Roles from '../../resources/UserRole';
import PricingHelper from "../../helpers/PricingHelper";
import Localization from '../../services/Localization';
import UserHelper from '../../helpers/UserHelper';
import CustomerService from '../../services/Customer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { withProductConfig } from '../../hooks/ProductConfigContext';
import { withStorefrontConfig } from '../../hooks/StorefrontSettingsContext';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';


import { EstimatedDate } from './EstimatedDate';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </Box>
    );
}

export class Product extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            product: null,
            price: null,
            availability: null,
            quantityAvailable: null,
            fetchingProduct: false,
            fetchingPrice: false,
            quantity: 1,
            updateButtonIsVisible: false,
            isConfiguredProduct: true,
            isObsoleteProduct: true,
            branchStockingType: null,
            productRating: null,
            productInstallation: null,
            productMiscellaneous: null,
            productMaterial: null,
            tabValue: 0,
            indexState: []
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.loadProduct(this.props.product);
        this.callApiWithCustomer();
        this.fetchAdvancedProductDetails();
        document.addEventListener("click", this.handleQtyBlur, false);
    }

    componentWillUnmount() {
        this.mounted = false;
        document.removeEventListener("click", this.handleQtyBlur, false);
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.callApiWithCustomer();
        }
    }

    setMountedState = (state, callback) => {
        if (this.mounted)
            this.setState(state, () => callback && callback());
    }

    setSku = (props, callback) => {
        if (props.product)
            this.setMountedState({ sku: props.sku }, () => { callback && callback() });
    }

    callApiWithCustomer = () => {
        if (this.props.fetchPriceOnLoad) {
            Api.fetch(url(this.props.selectedCustomer.id, this.props.selectedCustomer.companyCode, this.props.product.sku, this.props.currentUser.storefrontId, this.props.selectedCustomer.brandCode)).then(this.loadProduct);
        }
    }

    handleChange = (e) => {
        this.setMountedState({ [e.target.name]: e.target.value });
    }

    handleQtyBlur = (e) => {
        if (this.nodeQuantity && this.nodeQuantity.contains(e.target))
            return;
        if (this.state.quantity === "")
            this.setMountedState({ quantity: 1 });
        this.setMountedState({ quantity: this.state.quantity, updateButtonIsVisible: false });
    }

    handleQtyOnFocus = (e) => {
        this.setMountedState({ updateButtonIsVisible: true });
    }

    handleQtyChange = (e) => {
        this.setMountedState({ updateButtonIsVisible: true });
        if (e.target.value === "") {
            this.setMountedState({ quantity: e.target.value });
        }
        else if (isPositiveNumber(e.target.value)) {
            this.setMountedState({ quantity: parseInt(e.target.value) });
        }
    }

    handleQtyKeyUp = (e) => {
        if (e.keyCode === 13)
            this.handleQtyUpdateClick();
    }

    handleQtyUpdateClick = () => {
        this.setMountedState({ updateButtonIsVisible: false });
        this.fetchPricing();
    }

    fetchPricing = () => {
        if (this.props.selectedCustomer && this.props.selectedCustomer.billingType !== "1" && this.props.fetchPriceOnLoad && !this.state.isConfiguredProduct && !this.state.isObsoleteProduct) {
            this.setMountedState({ fetchingPrice: true }, () => {
                const priceAvailRequest = this.getPriceAvailabilityRequest();
                Api.fetch(`/api/products/GetPriceAndAvailability`, { method: 'POST', body: JSON.stringify(priceAvailRequest) }, false).then(this.handleResponse).then(this.loadPricing)
            });
        }
        else {
            this.setMountedState({ fetchingPrice: false });
        }
    }

    fetchAdvancedProductDetails = () => {
        return Api.fetch(`/api/products/GetAdvancedProductDetails/` + this.props.product.sku, { method: 'POST'}).then(this.handleResponse).then(this.loadAdvancedProductDetails)
    }

    handleResponse(res) {
        if (res)
            return res.json();
    }

    handleTabChange = (event, newValue) => {
        this.setMountedState({ tabValue: newValue });
    };

   handleChangeIndex = (index) => {
        setValue(index);
    };

    a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    getPriceAvailabilityRequest = () => {
        return {
            customerId: this.props.selectedCustomer.externalId,
            storefront: this.props.currentUser.storefrontNumber,
            company: this.props.selectedCustomer.companyCode,
            sku: this.props.product.sku,
            transactionQuantity: this.state.quantity?.toString() || "1",
            implementationId: this.props.StorefrontConfig.storefront.implementationId,
            useBranchStockingType: this.props.productConfig.settings.UseBranchStockingType
        };
    }

    handleAddToCart = () => {

        if (this.state.fetchingPrice === false)
            return this.addToCart();
        Notifications.error(this.props.productConfig.labels.FetchingPriceMessage);
    }

    addToCart = () => {
        const localized = this.props.productConfig.labels;
        let product = { product: this.state.product, quantity: this.state.quantity, netPrice: this.state.price };
        if (this.state.availability === "Unavailable" || this.state.availability === "Unknown")
            return Notifications.error(this.props.productConfig.labels.UnavailableMessage);

        this.props.cartActions.AddItemToCart(product, localized);
    }

    loadProduct = (data) => {
        const associatedProducts = data.associatedProducts.filter(x => x.imageUrl != null && x.imageUrl != '')
        data.associatedProducts = associatedProducts;
        this.setMountedState({
            product: data,
            fetchingProduct: false,
            sku: data.sku,
            isConfiguredProduct: PricingHelper.isItemConfigured(data),
            isObsoleteProduct: PricingHelper.isItemObsolete(data),
        });

        this.checkPriceAvailability = UserHelper.checkPriceAvailability(this.props.selectedCustomer, this.props.currentUser.storefrontNumber);
        this.fetchPricing();
    }

    loadPricing = (data) => {
        if (data) {
            this.setMountedState({
                price: data && this.props.currentUser.roles.includes(Roles.VIEW_NET_PRICE) ? { amount: data.price, currency: data.currency } : this.state.product.listPrice,
                fetchingPrice: false,
                availability: data.availability,
                quantityAvailable: data.quantityAvailable,
                branchStockingType: data.branchStockingType
            });
        }
    }

    loadAdvancedProductDetails = (data) => {
        if (data) {
            this.setMountedState({
                productRating: data.hasProductRating ? data.productRating : null,
                productInstallation: data.hasProductInstallation ? data.productInstallation : null,
                productMiscellaneous: data.hasProductMiscellaneous ? data.productMiscellaneous : null,
                productMaterial: data.hasProductMaterial ? data.productMaterial : null,
            })
            let intermediateState = [];
            intermediateState.push(this.state.productRating);
            intermediateState.push(this.state.productMaterial);
            intermediateState.push(this.state.productInstallation);
            intermediateState.push(this.state.productMiscellaneous);
            this.setMountedState({ indexState: intermediateState.filter(x => x != null) });
            
        }
    }

    showAvailabilityByLocation = () => {
        return (this.props.selectedCustomer
            && !this.state.updateButtonIsVisible
            && this.props.productConfig.settings.AvailabilityByLocation === true);
    }

    checkCreditHold = () => {
        if (this.props.selectedCustomer && this.props.currentUser.storefront === '6400')
            return CustomerService.checkCreditHold(this.props.selectedCustomer);
        else
            return false;
    }

    productAvailability = (stockingType, availability) => {
        // For use up items return unavailable.
        if (stockingType === "U" && availability === "backOrdered")
            return this.props.productConfig.labels.Unavailable;

        // This check for Australia showing available for manufacturing items for now. This might be removed in future
        if (this.state.branchStockingType === "M" && availability != null && this.props.productConfig.settings.ShowAvailable === true)
            return this.props.productConfig.labels.Available;

        return availability;
    }

    render() {
        const assocProductsLength = this.state.product?.associatedProducts?.length;
        const slides = assocProductsLength > 5 && assocProductsLength < 12 ? assocProductsLength / 2 : 5; //tweak for no of slides
        const infinite = this.state.product?.associatedProducts?.length < 5 ? false : true; // fix multiple rows when data is small
        var sliderSettings = {
            dots: true,
            slidesToShow: slides,
            speed: 500,
            slidesToScroll: slides,
            infinite: infinite,
            responsive: [
                {
                    breakpoint: 1440,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        };
        let creditHold = this.checkCreditHold();
        const maxLines = 100;
        let localized = this.props.productConfig.labels;
        const customerWarningMessage = (this.props.selectedCustomer.isActive === false) ? localized.InActiveCustomerSelectedWarningMessage : localized.ShipToNotSelectedWarningMessage;
       
        return (
            <Box className="fele-product-details">
                {
                    this.state.fetchingProduct &&
                    <Loading type="brand" />
                }
                {
                    creditHold &&
                    <Box id="CreditHoldWarning">
                        <MessageWarning title={""} message={localized.CreditHold_Warning} />
                    </Box>
                }
                {
                    this.props.selectedCustomer &&
                    !this.checkPriceAvailability &&
                    !this.props.multisearch &&
                    <Box id="CustomerIsBillToWarning">
                        <MessageWarning title={localized.ShipToNotSelectedWarningTitle} message={customerWarningMessage} />
                    </Box>
                }
                {
                    !this.state.fetchingProduct && this.state.product &&
                    <Box className="grid">
                        <Box className="container mb-5">
                            <Box className="row">
                                <Box className="col-md-12 col-xl-7">
                                    <Box className="row">
                                        {
                                            this.state.product.imageUrl &&
                                            <Box className="order-2 order-xl-1 col-md-12 col-xl-5 pt-0 pt-md-3 pt-xl-5">
                                                <img alt={this.state.product.sku} className="img-fluid" src={`${this.state.product.imageUrl}600x450px@1x/${this.state.product.sku}.jpg?q=80&x.template=y&crop=false`} />
                                                <Box className="image-disclaimer">{localized.ImageDisclaimer}</Box>
                                            </Box>
                                        }
                                        <Box className={this.state.product.imageUrl ? "order-1 order-xl-2 col-md-12 col-xl-7 pt-5" : "col-md-12 pt-5"}>
                                            <Box className="part-name">
                                                <Box className="content">
                                                    {this.state.product.description}
                                                </Box>
                                            </Box>
                                            {
                                                this.state.product.name &&
                                                <Box className="model-number pb-3">
                                                    <Box className="label">{localized.ModelNumber_Label}</Box>
                                                    <Box className="content">
                                                        {this.state.product.name}
                                                    </Box>
                                                </Box>
                                            }
                                            <Box className="sku">
                                                <Box className="label">{localized.Item_Label}</Box>
                                                {this.props.showLink && <Box className="content"><Link to={{ pathname: `/product/${this.state.product.sku}`, state: { selectedCustomer: this.props.selectedCustomer } }}>{this.state.product.sku}</Link></Box>}
                                                {!this.props.showLink && <Box className="content">{this.state.product.sku}</Box>}
                                            </Box>
                                        </Box>
                                        </Box>
                                        {this.state.product.associatedProducts.find(x => x.sku == 'dummy_sku_replacement_association_none') && <Box>
                                            <Typography variant='h5'>This Item does not have a Replacement</Typography>
                                        </Box>}
                                    <Box className="row">
                                        <Box className="col pt-5 pb-5">
                                            {
                                                this.state.product.generalDescription &&
                                                <Box className="long-description">
                                                    <Box className="content">
                                                        {this.state.product.generalDescription}
                                                    </Box>
                                                </Box>
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="col-md-12 col-xl-4 bg-secondary">
                                    <Box className="row">
                                        <Box className="order-3 col-md-12 pt-5">
                                            <Box className="row">
                                                <Box className="col-4">
                                                    {
                                                        this.state.product.listPrice && !this.props.productConfig.settings.HideListPrice === true &&
                                                        <Box className="list-price">
                                                            <Box className="label">{localized.ListPriceLabel}</Box>
                                                            <Box className="content"> <Price price={this.state.product.listPrice} country={this.props.selectedCustomer.address.country} /></Box>
                                                        </Box>

                                                    }
                                                </Box>
                                                <Box className="col-8">
                                                    {
                                                        this.checkPriceAvailability && this.state.product.landedCostRule !== "NEW" &&
                                                        <Box className="cost">
                                                            {
                                                                !this.state.fetchingPrice && !this.state.price && !this.state.availability &&
                                                                <Box className="button-wrapper">
                                                                    <button className="button button-alt" onClick={this.handleQtyUpdateClick}>{localized.AvailabilityCost_ButtonText}</button>
                                                                </Box>
                                                            }

                                                            {this.state.fetchingPrice && !this.props.productConfig.settings.HideYourCost === true && <Loading />}

                                                            {
                                                                !this.state.fetchingPrice && this.state.price && this.state.availability && !this.props.productConfig.settings.HideYourCost === true &&
                                                                <React.Fragment>
                                                                    <Box className="label">{localized.YourCostLabel}</Box>
                                                                    <Availability availability={this.productAvailability(this.state.product.stockingType, this.state.availability)} asIcon={true} />
                                                                    {this.state.availability === "Unavailable" && <span className="price">{localized.Unavailable}</span>}
                                                                    <Price price={this.state.price} country={this.props.selectedCustomer.address.country} />
                                                                </React.Fragment>
                                                            }
                                                        </Box>
                                                    }
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    {
                                        <Box className="row">
                                            <Box className="order-3 col-md-12 pt-5">
                                                <Box className="row">
                                                    <Box className="col-5">
                                                        {
                                                            <React.Fragment>
                                                                {
                                                                    this.props.productConfig.settings.EstimatedShipDate === true &&
                                                                    <EstimatedDate branchStockingType={this.state.branchStockingType}
                                                                        standardShippingDays={this.props.StorefrontConfig.settingsList.StandardShippingDays}
                                                                        localized={localized}
                                                                        dateFormat={this.props.StorefrontConfig.settingsList.DateFormat} />
                                                                }
                                                            </React.Fragment>
                                                        }
                                                    </Box>
                                                    <Box className="col-7">
                                                        {
                                                            this.checkPriceAvailability && this.state.product.landedCostRule !== "NEW" &&
                                                            <Box className="availability">
                                                                <Box className="label">{localized.AvailabilityLabel}:</Box>
                                                                {
                                                                    this.state.fetchingPrice &&
                                                                    <Box className="pill">
                                                                        <Loading />
                                                                    </Box>
                                                                }
                                                                {
                                                                    !this.state.fetchingPrice &&
                                                                    <Availability availability={this.productAvailability(this.state.product.stockingType, this.state.availability)} />
                                                                }
                                                                {
                                                                    !this.state.fetchingPrice && this.props.productConfig.settings.ShowUseUpQuantity === true && this.state.quantityAvailable > 0 && this.state.availability !== "Unavailable"
                                                                    && this.state.product.stockingType === "U" &&
                                                                    <Box style={{ whiteSpace: 'nowrap', marginTop: '4px' }}>
                                                                        <FontAwesomeIcon icon={faExclamationTriangle} color="orange" /> {localized.QuantityAvailableLabel.replace("{0}", this.state.quantityAvailable)}
                                                                    </Box>
                                                                }
                                                                {
                                                                    this.state.product && this.state.product.stockingType && this.state.product.stockingType.toUpperCase().trim() === 'C' &&
                                                                    <MessageWarning title={"Configured Item"} message={"Configured items are not currently available for purchase in FE-Direct, Please contact your account manager for price and availability"} />
                                                                }
                                                                {
                                                                    this.state.product && this.state.product.stockingType && this.state.product.stockingType.toUpperCase().trim() === 'O' &&
                                                                    <MessageWarning title={localized.ObsoleteItem} message={localized.OboleteItemsNotAvailable} />
                                                                }
                                                            </Box>

                                                        }
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    }

                                    {
                                        // availability by location
                                        this.checkPriceAvailability && this.state.product.landedCostRule !== "NEW" &&
                                        <>
                                            <Box className="add-to-cart">
                                                <Box className="flex">
                                                    <Authorize hasRoles={[Roles.DEVELOPER, Roles.VIEW_AVAILABILITY_BY_LOCATION, Roles.VIEW_NET_PRICE, Roles.PLACE_ORDERS]} displayMessage={false}>
                                                        <Box className="input-wrapper quantity" ref={node => (this.nodeQuantity = node)}>
                                                            {
                                                                this.props.cartLines < maxLines &&
                                                                <input
                                                                    type="text"
                                                                    name="quantity"
                                                                    value={this.state.quantity}
                                                                    onChange={this.handleQtyChange}
                                                                    onBlur={this.handleQtyBlur}
                                                                    onFocus={this.handleQtyOnFocus}
                                                                    onKeyUp={this.handleQtyKeyUp}
                                                                    autoComplete="off" />
                                                            }
                                                            {
                                                                this.state.updateButtonIsVisible && this.props.cartLines < maxLines &&
                                                                <Box className="update button-wrapper small">
                                                                    <button className="button button-alt" onClick={this.handleQtyUpdateClick} >{localized.ButtonText_CheckAvailability}</button>
                                                                </Box>
                                                            }
                                                        </Box>
                                                    </Authorize>
                                                    <Authorize hasRoles={[Roles.PLACE_ORDERS]} displayMessage={false}>
                                                        {
                                                            this.props.cartLines < maxLines &&
                                                            <Box className="button-wrapper add-to-order">
                                                                <button className="button" onClick={this.handleAddToCart}>{localized.ButtonText_AddToOrder}</button>
                                                            </Box>
                                                        }
                                                        {
                                                            this.props.cartLines >= maxLines &&
                                                            <Box style={{ whiteSpace: 'nowrap', fontSize: 'large', marginTop: '4px' }}>
                                                                <FontAwesomeIcon icon={faExclamationTriangle} color="orange" /> {localized.CartMaxLines}
                                                            </Box>
                                                        }
                                                    </Authorize>
                                                </Box>
                                            </Box>
                                            {this.showAvailabilityByLocation() && this.props.selectedCustomer.address?.country !== "US" &&
                                                <Box className="availability-by-loc pt-5 pb-5">

                                                    <Authorize hasRoles={[Roles.VIEW_AVAILABILITY_BY_LOCATION]} displayMessage={false}>
                                                        <SummaryAvailability sku={this.state.sku} userStorefront={this.props.currentUser.storefrontNumber} stockingType={this.state.product.stockingType}
                                                            quantity={this.state.quantity} customer={this.props.selectedCustomer} showAvailable={this.props.productConfig.settings.ShowAvailable} />
                                                    </Authorize>

                                                </Box>
                                            }
                                            {this.props.productConfig.settings.ShowDisplayLeadTimesButton === true &&
                                                <Box><hr />
                                                    <Box className="displayLeadTimes pt-3 pb-3">
                                                        <Box className="button-wrapper add-to-order">
                                                            <a href={this.props.StorefrontConfig.settingsList.DisplayLeadTimesURL} target='_blank'>
                                                                <button className="button">{localized.DisplayLeadTimesButtonLabel}</button>
                                                            </a>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            }
                                            {this.props.productConfig.settings.ShowOKCInventoryMessage === true && this.props.selectedCustomer.address?.country === "CA" &&
                                                <Box>
                                                    <Authorize hasRoles={[Roles.VIEW_AVAILABILITY_BY_LOCATION]} displayMessage={false}>
                                                        <hr />
                                                        <Box className="displayLeadTimes pt-3 pb-3">
                                                            <Box className="button-wrapper add-to-order">
                                                                <span>{localized.OKCInventoryMessage}</span>
                                                            </Box>
                                                        </Box>
                                                    </Authorize>
                                                </Box>
                                                }
                                                { this.props.selectedCustomer.address?.country === "AR" &&
                                                    <Box>
                                                        <Authorize hasRoles={[Roles.VIEW_AVAILABILITY_BY_LOCATION]} displayMessage={false}>
                                                            <hr />
                                                            <Box className="displayLeadTimes pt-3 pb-3">
                                                                <Box className="button-wrapper add-to-order">
                                                                    <span>{localized.TacuariInventoryAvailableMessage}</span>
                                                                </Box>
                                                            </Box>
                                                        </Authorize>
                                                    </Box>
                                                }
                                        </>
                                    }
                                </Box>
                                </Box>
                               {(this.state.productRating || this.state.productInstallation || this.state.productMiscellaneous || this.state.productMaterial) &&
                                    <Box sx={{ width: '50%' }}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <Tabs sx={{ background: "#D8D8D8"}} value={this.state.tabValue}
                                                onChange={this.handleTabChange}
                                                indicatorColor='primary'
                                                aria-label="basic tabs example">
                                                {this.state.productRating && <Tab sx={{ fontSize: 12}} label="Ratings" {...this.a11yProps(0)} />}
                                                {this.state.productMaterial && <Tab sx={{ fontSize: 12, }} label="Materials of Construction" {...this.a11yProps(1)} />}
                                                {this.state.productInstallation && <Tab sx={{ fontSize: 12, }} label="Installation" {...this.a11yProps(2)} />}
                                                {this.state.productMiscellaneous && <Tab sx={{ fontSize: 12, }} label="Other" {...this.a11yProps(2)} />}
                                            </Tabs>
                                        </Box>
                                        {this.state.productRating && <CustomTabPanel value={this.state.tabValue} index={this.state.indexState.findIndex(x  => x == this.state.productRating)}>
                                            <Box sx={{ height: '200px' }}>
                                                {this.state.productRating && Object.keys(this.state.productRating).map((key) => [key, this.state.productRating[key]]).map((item, index) => {
                                                    if (item[1] != null && item[0] != 'productId' && item[0] != 'sku') {

                                                        return (
                                                            <Box 
                                                            sx = {{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            marginBottom: '10px',
                                                            borderBottom: 1,
                                                            borderColor: 'divider',
    
                                                            }}
                                                            >
                                                                <Box sx={{ fontWeight: "bold" }}>{item[0].replace(/([A-Z])/g, ' $1').replace(/[^0-9](?=[0-9])/g, '$& ').toUpperCase()}</Box>
                                                                <Box sx={{ textAlign: 'right' }}>{item[1]}</Box>
                                                            </Box>
                                                        )
                                                    }
                                                })}
                                            </Box>

                                        </CustomTabPanel>}
                                        {this.state.productMaterial && <CustomTabPanel value={this.state.tabValue} index={this.state.indexState.findIndex(x => x == this.state.productMaterial)}>
                                            {this.state.productMaterial && Object.keys(this.state.productMaterial).map((key) => [key, this.state.productMaterial[key]]).map((item, index) => {
                                                if (item[1] != null && item[0] != 'productId' && item[0] != 'sku') {
                                                    return (
                                                        <Box sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            marginBottom: '10px',
                                                            borderBottom: 1,
                                                            borderColor: 'divider'
                                                        }}>
                                                            <Box sx={{ fontWeight: "bold" }}>{item[0].replace(/([A-Z])/g, ' $1').replace(/[^0-9](?=[0-9])/g, '$& ').toUpperCase()}</Box>
                                                            <Box sx={{ textAlign: 'right' }}>{item[1]}</Box>
                                                        </Box>
                                                    )
                                                }
                                            })}
                                        </CustomTabPanel>}
                                        {this.state.productInstallation && <CustomTabPanel value={this.state.tabValue} index={this.state.indexState.findIndex(x => x == this.state.productInstallation)}>
                                            {this.state.productInstallation && Object.keys(this.state.productInstallation).map((key) => [key, this.state.productInstallation[key]]).map((item, index) => {
                                                if (item[1] != null && item[0] != 'productId' && item[0] != 'sku') {
                                                    return (
                                                        <Box sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            marginBottom: '10px',
                                                            borderBottom: 1,
                                                            borderColor: 'divider'
                                                        }}>
                                                            <Box sx={{ fontWeight: "bold" }}>{item[0].replace(/([A-Z])/g, ' $1').replace(/[^0-9](?=[0-9])/g, '$& ').toUpperCase()}</Box>
                                                            <Box sx={{ textAlign: 'right'}}>{item[1]}</Box>
                                                        </Box>
                                                    )
                                                }
                                            })}
                                        </CustomTabPanel>}
                                        {this.state.productMiscellaneous && <CustomTabPanel value={this.state.tabValue} index={this.state.indexState.findIndex(x => x == this.state.productMiscellaneous)}>
                                            {this.state.productMiscellaneous && Object.keys(this.state.productMiscellaneous).map((key) => [key, this.state.productMiscellaneous[key]]).map((item, index) => {
                                                if (item[1] != null && item[0] != 'productId' && item[0] != 'sku') {

                                                    return (
                                                        <Box sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            marginBottom: '10px',
                                                            borderBottom: 1,
                                                            borderColor: 'divider'
                                                        }}>
                                                            <Box sx={{ fontWeight: "bold" }}>{item[0].replace(/([A-Z])/g, ' $1').replace(/[^0-9](?=[0-9])/g, '$& ').toUpperCase()}</Box>
                                                            <Box sx={{ textAlign: 'right' }}>{item[1]}</Box>
                                                        </Box>
                                                    )
                                                }
                                            })}
                                        </CustomTabPanel>}
                                    </Box>
                                }
                            {this.props.productConfig.settings.ShowRelatedItems === true && this.state.product && this.state.product.associatedProducts && this.state.product.associatedProducts.length > 0 &&
                                <Box style={{ marginTop: 50 }}>
                                    <h1>Related Items</h1>
                                    <Box className="col w-100">
                                        <Slider {...sliderSettings}>
                                            {
                                                    this.state.product.associatedProducts.map((s, key) => 

                                                        <Box>
                                                            <Box>
                                                                <Typography sx={{ height: "30px", width: "200px", fontSize: "12px", color: "gray" }}>{s.associationType}</Typography>
                                                            </Box>
                                                            <Box className="card border-0" style={{ width: 200 }}>
                                                                <Link to={{ pathname: `/product/${s.sku}`, state: { selectedCustomer: this.props.selectedCustomer } }}>
                                                                    <img className="card-img-top mx-auto" src={`${s.imageUrl}200x150px@1x/${s.sku}.jpg?q=80&x.template=y&crop=false`} alt={s.name} />
                                                                </Link>

                                                                <Box className="card-body" style={{ height: 75 }}>
                                                                    <p className="card-text" style={{ fontSize: 12, textAlign: 'center' }}>{s.description}</p>
                                                                </Box>
                                                            </Box>
                                                        </Box>    
                                                )
                                            }
                                        </Slider>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Box>

                }
            </Box>
        );
    }
};

function url(customerId, companyCode, sku, storefrontId, brandCode) {
    let url = `/api/products/${sku}/${customerId}/${companyCode}/${storefrontId}`;
    if (brandCode) {
        url = `${url}/${brandCode}`;
    }
    url = `${url}?locale=${Localization.language}`
    return url;
}

function isPositiveNumber(value) {
    let exp = new RegExp(/^[1-9]\d*$/)
    return exp.test(value);
}

Product.propTypes = {
    cartActions: PropTypes.object,
    selectedCustomer: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        currentUser: state.user.currentUser,
        cartLines: state.cart.items.length,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        cartActions: bindActionCreators(cartActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStorefrontConfig(withProductConfig(Product)));