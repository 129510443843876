import React from "react";
import { Col, Row } from "react-bootstrap";
import FormSection from "./FormSection";
import ItemNumberInput from "./input/ItemNumberInput";
import SerialNumberInput from "./input/SerialNumberInput";
import LoyaltyIDInput from "./input/LoyaltyIDInput";
import ReferenceNumberInput from "./input/ReferenceNumberInput";
import CommentSection from "./CommentSection";
import CalendarInput from "./input/CalendarInput";
import ClaimTypeSelector from "./input/ClaimTypeSelector";
import PumpManufacturerSelector from "./input/PumpManufacturerSelector";
import FailureTypeSelector from "./input/FailureTypeSelector";
import AdditionalCommentsInput from "./input/AdditionalCommentsInput";
import Alert from "./Alert";
import FileUpload from "./../button/FileUpload";

const ClaimForm = ({
  localized,
  itemNumberSubmit,
  itemNumberError,
  returnPolicy,
  obsolete,
  needsValidation,
  itemNeedsValidation,
  setItemNumberSubmit,
  handleEnter,
  validateItemNumber,
  serialNumberSubmit,
  serialNumberWarning,
  serialNumberErrors,
  pioneerBrand,
  setSerialNumberSubmit,
  validateSerialNumber,
  loyaltyIDSubmit,
  loyalityIDWarning,
  setLoyaltyIDSubmit,
  referenceNumberSubmit,
  referenceNumberError,
  setReferenceNumberSubmit,
  commentsSubmit,
  commentsError,
  stringLimittext,
  commentHandleChange,
  dateInstalledSubmit,
  installDateError,
  minDate,
  updateDateInstalled,
  dateFailedSubmit,
  failureDateError,
  updateDateFailed,
  claimType,
  setClaimType,
  fetching,
  canAdd,
  fetchingAsync,
  claimTypeOptionError,
  itemDispositions,
  isPioneerItem,
  claimLines,
  uploadClaimAttachmentsToLocalClaimItem,
  duplicateSerial,
  isValidClaim,
  dateFormat,
  setDetailsCommentSubmit,
  detailComment,
  uploadFiles,
  productType,
  pumpManufacturer,
  setPumpManufacturer,
  failureType,
  setFailureType,
  locale,
}) => {
  return (
    <Col style={{ paddingRight: "3rem" }}>
      <div>
        <Row>
          <Col style={{ marginBottom: "1.5rem", marginRight: "3rem" }}>
            <FormSection label={localized.ItemNumber} required>
              <ItemNumberInput
                itemNumberSubmit={itemNumberSubmit}
                itemNumberError={itemNumberError}
                returnPolicy={returnPolicy}
                obsolete={obsolete}
                needsValidation={needsValidation}
                itemNeedsValidation={itemNeedsValidation}
                setItemNumberSubmit={setItemNumberSubmit}
                handleEnter={handleEnter}
                validateItemNumber={validateItemNumber}
              />
            </FormSection>

            <FormSection
              label={localized.SerialNumber}
              required
              toolTipMsg={localized.SerialNoHoverTipMsg}
            >
              <SerialNumberInput
                serialNumberSubmit={serialNumberSubmit}
                serialNumberWarning={serialNumberWarning}
                serialNumberErrors={serialNumberErrors}
                pioneerBrand={pioneerBrand}
                needsValidation={needsValidation}
                localized={localized}
                setSerialNumberSubmit={setSerialNumberSubmit}
                handleEnter={handleEnter}
                validateSerialNumber={validateSerialNumber}
                itemNumberSubmit={itemNumberSubmit}
              />
            </FormSection>

            <FormSection label={localized.LoyaltyID}>
              <LoyaltyIDInput
                loyaltyIDSubmit={loyaltyIDSubmit}
                needsValidation={needsValidation}
                loyalityIDWarning={loyalityIDWarning}
                setLoyaltyIDSubmit={setLoyaltyIDSubmit}
                handleEnter={handleEnter}
              />
            </FormSection>

            <FormSection
              className="claim-refNo"
              label={localized.ReferenceNumber}
              toolTipMsg={localized.RefNoHoverTipMsg}
              required
            >
              <ReferenceNumberInput
                referenceNumberSubmit={referenceNumberSubmit}
                referenceNumberError={referenceNumberError}
                needsValidation={needsValidation}
                setReferenceNumberSubmit={setReferenceNumberSubmit}
                handleEnter={handleEnter}
              />
            </FormSection>

            <CommentSection
              localized={localized}
              stringLimittext={stringLimittext}
              commentsSubmit={commentsSubmit}
              commentsError={commentsError}
              needsValidation={needsValidation}
              handleEnter={handleEnter}
              commentHandleChange={commentHandleChange}
            />
          </Col>

          <Col>
            <FormSection
              label={localized.DateInstalled}
              required={!isPioneerItem ? true : false}
              datacy="dateInstalled-submit"
            >
              <CalendarInput
                date={dateInstalledSubmit}
                dateError={installDateError}
                minDate={minDate}
                needsValidated={needsValidation}
                callBackFunction={updateDateInstalled}
                showIcon={true}
                pioneerBrand={pioneerBrand}
                dateFormat={dateFormat}
              />
            </FormSection>

            <FormSection
              label={localized.DateFailed}
              required={!isPioneerItem ? true : false}
              datacy="dateFailed-submit"
            >
              <CalendarInput
                date={dateFailedSubmit}
                dateError={failureDateError}
                minDate={minDate}
                needsValidated={needsValidation}
                callBackFunction={updateDateFailed}
                showIcon={true}
                pioneerBrand={pioneerBrand}
                dateFormat={dateFormat}
              />
            </FormSection>

            <FailureTypeSelector
              failureType={failureType}
              setFailureType={setFailureType}
              localized={localized}
              productType={productType}
              locale={locale}
              needsValidation={needsValidation}
            />

            <PumpManufacturerSelector
              pumpManufacturer={pumpManufacturer}
              setPumpManufacturer={setPumpManufacturer}
              localized={localized}
              productType={productType}
            />

            <ClaimTypeSelector
              localized={localized}
              returnPolicy={returnPolicy}
              obsolete={obsolete}
              isPioneerItem={isPioneerItem}
              itemDispositions={itemDispositions}
              claimType={claimType}
              setClaimType={setClaimType}
              fetching={fetching}
              canAdd={canAdd}
              fetchingAsync={fetchingAsync}
              needsValidation={needsValidation}
              claimTypeOptionError={claimTypeOptionError}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <FormSection
              label={localized.AdditionalComments}
              subLabel={localized.AdditionalCommentsSubLabel}
              required={failureType.isCommentsRequired}
            >
              <AdditionalCommentsInput
                required={failureType.isCommentsRequired}
                needsValidation={needsValidation}
                setDetailsCommentSubmit={setDetailsCommentSubmit}
                detailComment={detailComment}
                editable={false}
              />
            </FormSection>
          </Col>
        </Row>

        <FileUpload
          isPioneerItem={isPioneerItem}
          localized={localized}
          files={uploadFiles}
          uploadClaimAttachmentsToLocalClaimItem={
            uploadClaimAttachmentsToLocalClaimItem
          }
          claimLine={claimLines}
        />

        {obsolete && returnPolicy === "F" && itemNumberSubmit.length > 0 && (
          <Alert type="warning" message={localized.ObsoleteFastTrackWarning} />
        )}

        {obsolete && returnPolicy !== "F" && itemNumberSubmit.length > 0 && (
          <Alert type="error" message={localized.ObsoleteNonFastTrackError} />
        )}

        {duplicateSerial && (
          <Alert type="error" message={localized.DuplicateSerialNumberInCart} />
        )}

        {isValidClaim && (
          <Alert
            type="error"
            message={localized.DuplicateSerialNumberOnClaimSubmit}
          />
        )}
      </div>
    </Col>
  );
};

export default ClaimForm;
