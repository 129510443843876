import React from 'react';
import PropTypes from 'prop-types';
import LineRequestDate from './LineRequestDate';
import PCDiscountCodes from './PCDiscountCodes';
import { withCartConfig } from '../../hooks/CartConfigContext';

const DeliveryDiscount = props => {
            return (
            <React.Fragment>
                <LineRequestDate calculating={props.calculating} item={props.item} updateCartItem={props.updateCartItem} dateFormat={props.dateFormat} headerRequestDate={props.headerRequestDate} />
                <PCDiscountCodes currentUser={props.currentUser} item={props.item} updateCartItem={props.updateCartItem}
                    pCDiscountCodes={props.CartConfig.pcDiscountCodes} requestDate={props.requestDate} localized={props.CartConfig.labels} />
            </React.Fragment>
        );
    }


DeliveryDiscount.propTypes = {
    item: PropTypes.object,
    updateCartItem: PropTypes.func,
    currentUser: PropTypes.object,
    dateFormat: PropTypes.string,
    pcDiscountCodes: PropTypes.object,
    headerRequestDate: PropTypes.string,
    calculating: PropTypes.bool
};

export default withCartConfig(DeliveryDiscount);