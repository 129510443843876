import React from 'react';
import PropTypes from 'prop-types';
import AngleDown from '../icons/AngleDown';
import UserHelper from '../../helpers/UserHelper';
import * as Roles from '../../resources/UserRole';
import { withCartConfig } from '../../hooks/CartConfigContext';

export class FreightHandlingCodes extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setMountedState = (state, callback) => {
        if (this.mounted)
            this.setState(state, () => callback && callback());
    }

    render() {
        const { localized } = this.props;
        return (
            this.props.CartConfig.freightHandlingCodes.length > 0 &&
            <React.Fragment>
                <div className="fele-cart-freight-handling-codes">
                    <div className="label">
                        {localized.FreightHandlingCodesLabel}
                    </div>
                    <div className="fele-content">
                        <div className="input-wrapper">
                            <div className="select-wrapper">
                                    <select disabled={this.props.isFetching || !UserHelper.hasRoles([Roles.CAN_SELECT_FREIGHT_CODES], this.props.currentUser)} onChange={this.props.handleChange} value={this.props.freightHandlingCode}>
                                        <option value={0} > { localized.SelectFreightHandlingCode }</option>
                                    {
                                        this.props.CartConfig.freightHandlingCodes.map(
                                            (freightHandlingCode, index) =>
                                                <option key={index} value={freightHandlingCode.id}>{freightHandlingCode.description}</option>
                                        )}</select>
                                <AngleDown />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


FreightHandlingCodes.propTypes = {
    handleChange: PropTypes.func,
    currentUser: PropTypes.object,
    freightHandlingCode: PropTypes.string,
};

export default withCartConfig(FreightHandlingCodes);
